

















































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { articleTypes ,remoteServiceBaseUrl} from "@/libs/appconst";


@Component
export default class AdvertList extends Vue {
    pagerHeader: any = {
        title: "广告列表",
        desc: "广告列表",
        breadcrumb: ["广告管理", "广告列表"]
    };
    loading: boolean = false;
    filter:any={
        page: 1,
        size: 10,
        type: null,
        position: null,
        Keywords:''
    }
    tableData: any = {
        items: [],
        totalCount: 0
    };
    isAdd:boolean=true
    ADialog:any={
        show:false,
        loading:false,
        form:{
            type:null,
            position:null,
            merchantId:null,
            imageUrl:'',
            linkUrl:'',
            beginDate:null,
            endDate:null,
            beginTime:null,
            endTime:null,
            isActive:true,
            remark:'',
            maxDistance:''
        },
        rules: {
            type: [{ required: true, message: "请选择类型", trigger: "blur" }],
            position: [{ required: true, message: "请选择位置", trigger: "blur" }],
            imageUrl: [{ required: true, message: "请上传图片", trigger: "blur" }],
            beginDate: [{ required: true, message: "请选择时间", trigger: "blur" }],
            beginTime: [{ required: true, message: "请选择时间段", trigger: "blur" }],
            merchantId:[{ required: true, message: "请选择商家ID", trigger: "blur" }],
            maxDistance:[{ required: true, message: "请输入最大距离", trigger: "blur" }]
        },
    }
    isMerchant:boolean=false
    /*时间数组*/
    Dates:any=[]
    Times:any=[new Date,new Date]
    /*搜索框*/ 
    merchantItems:any=[]
    merchantSearchLoading: boolean = false;
    /*图片*/ 
    dialogImageUrl:any='';//放大图片地址
    dialogVisible:any=false;
    isCustom:boolean=false;
    isCustom1:boolean=false
    merchantId:any=null //  显示商家信息
    remoteServiceBaseUrl:any=remoteServiceBaseUrl
    /*获取枚举值*/ 
    get levelOptions(){
        return this.getEnum('AdType')
    }
    get locationOptions(){
        return this.getEnum('AdPosition')
    }
    /*监听属性*/
    @Watch('ADialog.form.type')//监听类型选择
    typeChange(value:any){
        if(value==2){
            this.isMerchant=true
        }else{
            this.isMerchant=false
            this.ADialog.form.merchantId=null
            this.ADialog.form.maxDistance=''
        }
    }
    @Watch('Dates')
    datesChange(value:any){
        if(value){
            this.ADialog.form.beginDate=value[0]
            this.ADialog.form.endDate=value[1]
        }
    }
    /*分页*/
    handleSizeChange(val: number) {
        this.filter.size = val;
        this.getData();
    } 
    handleCurrentChange(val: number) {
        this.filter.page = val;
        this.getData();
    }

    /*获取列表*/
    async getData(){
        this.loading = true;
        try {
        let res = await this.$ajax.get("/api/services/app/Ad/GetPagedList", {
            params: {
            ...this.filter
            }
        });
        if (res.data.success) {
            this.tableData = res.data.result;
        }
        } catch (error) {
            console.log(error);
        }
        this.loading = false;
    }
    /*生命周期 */
    created() {
        this.getData()
    }
    /*点击事件*/
     search(){//搜索
        this.getData()
     }
     add(){
         this.ADialog.show=true
         this.isAdd=true
         this.ADialog.form={
                type:null,
                position:null,
                merchantId:null,
                imageUrl:'',
                linkUrl:'',
                beginDate:null,
                endDate:null,
                beginTime:null,
                endTime:null,
                isActive:true,
                remark:'',
                maxDistance:''
         }
         this.Dates=[]
         this.Times=['00:00:00',"23:59:59"]
         this.isCustom=false
         this.isCustom1=false
     }
    //  点击时间段 选择
     TimeChange(e:any){
         console.log(e)
         this.ADialog.form.beginTime=e[0]
          this.ADialog.form.endTime=e[1]
     }
    //  点击自定义时间
    customChange(value:any){  //自定义时间
        if(value){
            this.ADialog.form.beginDate=''
            this.ADialog.form.endDate=""
        }else{
            this.ADialog.form.beginDate=null
            this.ADialog.form.endDate=null
        }
    }
    customChange1(value:any){//自定义u时间段
        if(value){
            this.ADialog.form.beginTime='00:00:00'
            this.ADialog.form.endTime="23:59:59"
        }else{
            this.ADialog.form.beginTime=null
            this.ADialog.form.endTime=null
        }
    }
    //  保存
    async saveAgent(){
        (this.$refs.editAgentForm as any).validate(async (valid: boolean)=>{
            if(valid){
                if(this.isAdd){
                    try{
                        var res = await this.$ajax.post(
                            "/api/services/app/Ad/Create",
                            this.ADialog.form
                        );
                        if (res.data.success) {
                            var data = res.data.result;
                            this.$notify.success(`创建成功`);
                            this.getData();
                            this.ADialog.show=false
                        }
                    }catch(error){
                        console.log(error)
                    }
                }else{
                    try {
                        var res =await this.$ajax.put(
                            '/api/services/app/Ad/Update',
                            this.ADialog.form
                        )
                        if (res.data.success) {
                            var data = res.data.result;
                            this.$notify.success(`修改成功`);
                            this.getData();
                            this.ADialog.show=false
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
                

            }
        })
     }
    async readerMerchantOptions(query: string) {//  搜索商家
        this.merchantSearchLoading = true;
        try {
            var res = await this.$ajax.get(
            "/api/services/app/Merchant/GetPagedList",
            {
            params: {
                page: 1,
                size: 10,
                keywords: query,
                categoryId: null,
                agentId: null,
                provinceCode: null,
                cityCode: null,
                areaCode: null
            }
            }
        );
        this.merchantItems = res.data.result.items;
        } catch (error) {}
        this.merchantSearchLoading = false;
    }
    /*上传图片*/
    // 上传图片和删除图片和放大图片
    relyhandleAvatarSuccess(res:any, file:any) {  
        this.ADialog.form.imageUrl=res.result.fileUrl
    }
    handfail(err:any, file:any, fileList:any){
        this.$notify.warning(`上传失败`);
    } 
    /*表格操作*/ 
    async editEditAgentDialog(value:any){
        this.ADialog.show=true
        this.isAdd=false
        this.loading = true;
        try {
        let res = await this.$ajax.get("/api/services/app/Ad/Get", {
            params: {
                id:value
            }
        });
        if (res.data.success) {
           this.ADialog.form = res.data.result;
            if(res.data.result.merchant){
                this.merchantId=res.data.result.merchant.name+`【${res.data.result.merchantId}】`
            }else{
                this.merchantId=null
            }
           
           
           if(res.data.result.beginDate){
               this.isCustom=true
                this.Dates=[this.ADialog.form.beginDate ,this.ADialog.form.endDate]//
           }else{
               this.isCustom=false
               this.Dates=[]
           }
           if(res.data.result.beginTime){
                this.isCustom1=true
               this.Times=[this.ADialog.form.beginTime+':00',this.ADialog.form.endTime+':00' ]//
           }else{
               this.isCustom1=false
           }      
        }
        } catch (error) {
            console.log(error);
        }
        this.loading = false;
    }
    async deleteMerchant(id:string){
        var loading = this.$loading({
             text: "删除中..."
        });
        try {
            var res = await this.$ajax.delete("/api/services/app/Ad/Delete", {
            params: {
                id
            }
            });
            if (res.data.success) {
            this.$notify.success("删除成功");
            this.getData();
            }
      } catch (error) {}
       loading.close();
    }
}
